'use client';
import React, { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

import { cn } from '@skybeam/shared-utils/classnames/cn';
import { Button } from '@skybeam/shared-ui-kit/ui/button';
import { NavbarMobile } from './navbar-mobile';
import { NavItem } from './nav-item';
import { ReactComponent as Logo } from '../../../../public/skybeam-logo-with-text-blue.svg';
import { ReactComponent as MenuSVG } from '../../../../public/menu.svg';

import styles from './navbar.module.css';

const navItems = [
  { link: '/insights', label: 'Insights Hub' },
  { link: '/blog', label: 'Blog' },
  { link: '/pricing', label: 'Pricing' },
  { link: '/about', label: 'About Us' },
];

export const Navbar: React.FC = () => {
  const pathname = usePathname();

  const dashboardUrl = process.env.NEXT_PUBLIC_DASHBOARD_URL;

  const getInitialNavbarMode = () => {
    if (pathname.startsWith('/blog/')) {
      return 'white';
    }
    if (pathname !== '/' && pathname !== '/blog' && pathname !== '/pricing') {
      return 'light';
    }
    return 'dark';
  };

  const [isNavbarMobileOpen, setIsNavbarMobileOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(false);
  const [navbarMode, setNavbarMode] = useState<'light' | 'dark' | 'white'>(
    getInitialNavbarMode,
  );

  const handleOpenMenu = () => {
    setIsNavbarMobileOpen(true);
  };

  const handleCloseMenu = () => {
    setIsNavbarMobileOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('[data-section]');
      let currentSection: any = null;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 80 && rect.bottom >= 80) {
          currentSection = section as HTMLElement;
        }
      });

      if (currentSection) {
        const sectionId = currentSection.getAttribute('data-section');

        setIsTransparent(
          window.scrollY <= 0 && sectionId === 'heading-section',
        );

        if (
          sectionId === 'heading-section' ||
          sectionId === 'faq-section' ||
          sectionId === 'resources-section' ||
          sectionId === 'pricing-heading-section'
        ) {
          setNavbarMode('dark');
        } else if (sectionId === 'article-section') {
          setNavbarMode('white');
        } else {
          setNavbarMode('light');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [pathname]);

  if (isNavbarMobileOpen) {
    return <NavbarMobile navbarMode={navbarMode} setClose={handleCloseMenu} />;
  } else {
    return (
      <div
        data-test-header
        className={cn(
          styles.navbar,
          styles[navbarMode],
          { [styles.transparent]: isTransparent },
          'tablet:py-5 z-[100] flex w-full justify-center border-b border-t border-transparent py-2 transition-colors duration-500',
        )}
      >
        <div className="tablet:px-6 laptop:px-10 desktop:px-0 desktop:max-w-[71rem] flex w-full max-w-full items-center justify-between px-0">
          <div className="flex">
            <MenuSVG
              className={cn(
                'tablet:mr-8 desktop:hidden mr-4',
                navbarMode === 'white' ? 'text-[#0A0A0A]' : 'text-white',
              )}
              onClick={handleOpenMenu}
            />
            <Link href="/">
              <Logo
                id="skybeam-logo"
                className="tablet:size-full h-[32px] w-[99px]"
              />
            </Link>
            <div className="desktop:flex hidden items-center space-x-8 pl-10">
              {navItems.map(({ label, link }) => (
                <NavItem
                  key={link}
                  href={link}
                  className="px-4 py-1"
                  isActive={pathname === link || pathname.startsWith(link)}
                >
                  {link !== '/insights' ? (
                    label
                  ) : (
                    <span className="h-5 space-x-2">
                      <span
                        className={cn(
                          'body-medium bg-gradient-to-r bg-clip-text text-transparent',
                          {
                            'from-[hsla(199,91%,74%,1)] to-[hsla(83,87%,76%,1)]':
                              navbarMode === 'dark' || navbarMode === 'light',
                          },
                          {
                            'from-[hsla(216,100%,50%,1)] to-[hsla(149,65%,39%,1)]':
                              pathname === link ||
                              pathname.startsWith(link) ||
                              navbarMode === 'white',
                          },
                        )}
                      >
                        {label}
                      </span>
                      <span className="body">🎉</span>
                    </span>
                  )}
                </NavItem>
              ))}
            </div>
          </div>
          <div className="tablet:flex hidden items-center gap-x-2">
            <NavItem
              href={`${dashboardUrl}/sign-in`}
              className={styles.navButton}
            >
              <Button
                data-test-sign-in-btn
                className={cn(
                  'body-medium h-[38px] hover:no-underline',
                  navbarMode !== 'white' ? 'text-white' : 'text-zinc-950',
                )}
                variant="link"
              >
                Log In
              </Button>
            </NavItem>
            <Link href={`${dashboardUrl}/sign-up`}>
              <Button
                data-test-sign-up-btn
                className={cn(
                  'body-medium h-[38px] rounded-lg',
                  navbarMode === 'dark'
                    ? 'marketing-primary-button'
                    : 'marketing-tertiary-button',
                )}
                variant="secondary"
              >
                Start Now
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
};
